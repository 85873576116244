
 import { defineComponent ,ref,reactive,toRefs,getCurrentInstance} from "vue";
 import storage from '@/assets/js/localStorage'
 import { Dialog } from "vant";
import { useRouter } from "vue-router"; 
 export default defineComponent({
    components:{
        [Dialog.name]: Dialog 
    },
    props: {
        canShowModal:{
        type: Boolean,
        default: false
      }
    },
    setup (props,{emit}) {
        const isLogin: any = ref(true)
        const isKnowFrom = ref(false)
        const router = useRouter()
        const state:any = reactive({
            userInfo: {
                create_time: 1635237580,
                email: "5****@moe",
                email_validated: 0,
                head_url: "//xdream-test.oss-cn-shanghai.aliyuncs.com/avatar/936198?1635492869",
                idcard_verify_count: 0,
                idcard_verify_time: 0,
                isPass: true,
                level: 0,
                login_phone: "**********7573",
                name: "Sexy丶",
                phone: "",
                phone_validated: 1,
                update_time: 1654066363,
                user_id: 936198,
                user_idcard: null,
                user_info: null
            }
        })
        isLogin.value = storage.getLoginToken() || false
        state.userInfo = storage.getUserInfo() || {}
       const signOutHandle = ()=>{
        Dialog.confirm({
        message: '确认退出？',
        closeOnClickOverlay: true,
        }).then(() => {
            router.push({path:'/shopping/login',query:{
                from: `\/shopping\/detail\/${storage.getGoodsParams().site}\/${storage.getGoodsParams().id}`
            }})
            setTimeout(() => {
               window.localStorage.clear()
            }, 0);
        }) .catch(() => {
        });
        
       }
        const {proxy} = getCurrentInstance() as any
        return {
            ...toRefs(state),
            isLogin,
            isKnowFrom,
            signOutHandle,  
        }
    }
 })
